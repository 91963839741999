import React from 'react';
import styled from 'styled-components';
import Title from '../components/Title';
import ContactDetails from '../components/ContactDetails';
import Layout from '../components/Layout';

const contact = () => {
  return (
    <Layout>
      <Container className="section" id="contact">
        <div className="section-center">
          <Title title="Get In Touch" />
          <div className="contact-content">
            <ContactDetails />
          </div>
        </div>
      </Container>
    </Layout>
  );
};

const Container = styled.section`
  width: 100%;

  .section-center {
    display: flex;
    flex-direction: column;
  }
  .contact-content {
    display: flex;
    flex-direction: column;
  }

  @media (min-width: 800px) {
    .contact-content {
      display: flex;
      flex-direction: row;
      justify-content: center;
    }
  }
`;

export default contact;
