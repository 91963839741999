import React from 'react';
import styled from 'styled-components';
import { Link } from 'gatsby';

const Title = ({ title, link, subtitle = '' }) => {
  return (
    <Container className="section-title">
      <Link to={link}>
        <h2 className="title">{title}</h2>
      </Link>
      {subtitle && <h4 className="subtitle">{subtitle}</h4>}
    </Container>
  );
};

const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-bottom: 4rem;

  .title {
    font-weight: 200;
    text-transform: uppercase;
  }
  .subtitle {
    margin-top: 1rem;
  }
`;

export default Title;
