import React from 'react';
import Navbar from './Navbar';
import Footer from './Footer';
// import Banner from './Banner';

const Layout = ({ children }) => {
  // const [isClosed, setIsClosed] = useState(false);
  // const handleClose = () => {
  //   setIsClosed(true);
  // };
  return (
    <>
      <Navbar />
      {/* {isClosed || <Banner handleClose={handleClose} />} */}
      {children}
      <Footer />
    </>
  );
};

export default Layout;
