import React from 'react';

import { FaFacebookF } from 'react-icons/fa';
import { FaTwitter } from 'react-icons/fa';
import { FaInstagram } from 'react-icons/fa';
import { FaLinkedinIn } from 'react-icons/fa';
import { FaGithub } from 'react-icons/fa';

export const links = [
  {
    id: 1,
    name: 'home',
    link: '/',
  },
  // {
  //   id: 2,
  //   name: 'blog',
  //   link: '/',
  // },
  {
    id: 3,
    name: 'properties',
    link: '/properties',
    subMenu: [
      // {
      //   id: 'a',
      //   name: 'for sale',
      //   link: '/properties/forsale',
      // },
      {
        id: 'b',
        name: 'sold',
        link: '/properties/sold',
      },
      // {
      //   id: 'c',
      //   name: 'garage sale',
      //   link: '/garagesale',
      // },
    ],
  },
  {
    id: 4,
    name: 'about',
    link: '/about',
  },
  {
    id: 5,
    name: 'contact',
    link: '/contact',
  },
];

export const contactInfo = [
  {
    id: '1',
    title: 'email',
    info: 'maxwright@regencyre.com',
  },
  {
    id: '2',
    title: 'phone',
    info: '949 887 3940',
  },
  {
    id: '3',
    title: 'location',
    info: '25950 Acero, Suite 100 Mission Viejo, California 92692',
  },
];

export const socialIcons = [
  { id: '1', url: '#', icon: <FaFacebookF /> },
  { id: '2', url: '#', icon: <FaTwitter /> },
  { id: '3', url: '#', icon: <FaInstagram /> },
  { id: '4', url: '#', icon: <FaLinkedinIn /> },
  { id: '5', url: '#', icon: <FaGithub /> },
];
