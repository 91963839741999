import React, { useState } from 'react';
import NavLinks from './NavLinks';
import styled from 'styled-components';
import { FaBars } from 'react-icons/fa';

const Navbar = () => {
  // state
  const [isOpen, setIsOpen] = useState(false);
  // functions
  const closeNavbar = () => {
    setIsOpen(false);
  };
  const handleToggle = () => {
    setIsOpen(!isOpen);
  };

  return (
    <Nav className="nav ">
      <div className="nav-center">
        <button
          type="button"
          className="nav-toggle links-btn"
          onClick={handleToggle}
        >
          <FaBars />
        </button>
        <NavLinks isOpen={isOpen} closeNavbar={closeNavbar} />
      </div>
    </Nav>
  );
};

const Nav = styled.nav`
  width: 100%;
  display: flex;
  background-color: #ffffff;

  .nav-center {
    width: 100%;
    max-width: 1140px;
    margin: 0 auto;
    padding: .5rem 1rem;
    display: flex;
    flex-direction: column;
    align-items: start;
  }
  .nav-toggle {
    background: #ffffff;
    border: 1px solid #e5e5e5;
    border-radius: 4px;
    padding: .25rem .75rem;
    svg {
      font-size: 1.5rem;
      font-weight: 200;
      color: #888888;
      
    }
  }

  @media (min-width: 800px) {
    height: 4rem;
    align-items: center;

      .nav-center {
        flex-direction: row;
        justify-content: center;
      }
      .nav-toggle {
      display: none;
      }
  }
}
`;

export default Navbar;
