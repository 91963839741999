import React from 'react';
import styled from 'styled-components';
import { FiChevronDown } from 'react-icons/fi';
import { Link } from 'gatsby';
import { links } from './utils';

const NavLinks = ({ isOpen, closeNavbar }) => {
  return (
    <Container className={`nav-links ${isOpen ? 'show' : 'collapse'}`}>
      {links.map((item) => {
        const { id, link, subMenu, name } = item;

        if (!subMenu) {
          return (
            <li key={id}>
              <Link to={link} className="link" onClick={closeNavbar}>
                {name}
              </Link>
            </li>
          );
        } else {
          return (
            <li key={id} className="submenu-container">
              <Link
                to={link}
                className="link submenu-link"
                onClick={closeNavbar}
              >
                {name} <FiChevronDown />
              </Link>
              <ul className="submenu">
                {subMenu.map((subLink) => {
                  return (
                    <li>
                      <Link to={subLink.link} className="sublink">
                        {subLink.name}
                      </Link>
                    </li>
                  );
                })}
              </ul>
            </li>
          );
        }
      })}
    </Container>
  );
};

const Container = styled.ul`
  width: 100%;
  justify-content: space-evenly;
  
    li {
      padding: 1rem 1.25rem;
    }
    .link {
      width: 100%;
      color: #333;
      font-weight: 200;
      letter-spacing: 0.125rem;
      text-transform: uppercase;
      &:hover {
        color: #767f89;
      }
    }
    .link-scrolled {
      color: #000;
    }
    .submenu-container {
      overflow: hidden;
    }
    .submenu-container:hover .submenu {
      display: flex;
    }
    .submenu-container svg {
      margin-left: .5rem;
      font-size: 1rem;
    }
    .submenu-link {
      display: flex;
      align-items: center;
    }
    .submenu {
      display: none;
      position: absolute;
      flex-direction: column;
      justify-content: center;
      background-color: #ffffff;
      min-width: 160px;
      z-index: 1;
    }
    .sublink {
      display: flex;
      text-align: center;
      text-transform: uppercase;
      color: #333;
      font-size: .75rem;
      font-weight: 500;
    }
    .sublink:hover {
        color: #767f89;
    }

    @media (min-width: 800px) {
      .link-scrolled {
        color: #000;
      }
    }
  }
`;

export default NavLinks;
