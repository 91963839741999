import React from 'react';
import styled from 'styled-components';
// import Social from './Social';

const Footer = () => {
  const year = new Date().getFullYear();
  return (
    <Container className="section">
      {/* <Social /> */}
      <div className="footer-center">
        <div className="footer-text">
          <p>
            Copyright &copy; {year} All rights reserved | This website was made
            by{' '}
            <a href="https://www.joeyjenson.com/" className="website-link">
              Joey Jenson
            </a>
          </p>
        </div>
      </div>
    </Container>
  );
};

const Container = styled.footer`
  background-color: #fcfcfc;

  .footer-center {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .footer-text {
    color: #8c8c8c;
    text-align: center;
    /* margin-top: 4rem; */
  }
  .website-link {
    color: #21857c;
    &:hover {
      text-decoration: underline;
      color: #33c1b6;
    }
  }
`;

export default Footer;
