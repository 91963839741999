import React from 'react';
import styled from 'styled-components';
import { contactInfo } from './utils';

const ContactDetails = () => {
  return (
    <Container className="contact-info">
      {/* <h3 className="contact-title">My Contact Details</h3> */}
      <ul className="info">
        {contactInfo.map(({ id, title, info }) => {
          return (
            <li key={id} className="info-text">
              <h4 className="info-title">{title}</h4>
              <span>{info}</span>
            </li>
          );
        })}
      </ul>
    </Container>
  );
};

const Container = styled.div`
  /* width: 100%; */

  .contact-title {
    font-weight: 200;
    margin: 2rem 0 4rem;
  }
  .info {
  }
  .info-title {
    margin-bottom: 0.5rem;
    font-size: 0.75rem;
    font-weight: 200;
    text-transform: uppercase;
    color: #2c3e50;
  }
  .info-text {
    margin-bottom: 1.35rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  @media (min-width: 800px) {
    /* margin-left: 3rem; */
  }
`;

export default ContactDetails;
